import Vue from "vue";
// import VueRouter from "vue-router";
// Vue.use(VueRouter);
import { IonicVueRouter } from "@ionic/vue";
Vue.use(IonicVueRouter);

const router = new IonicVueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/Home.vue")
      /*
    path: "/",
    component: Home,
    children: [{
        path: 'sodogu',
        name: 'home.sodogu',
        components: {
          default: Home,
          dialog: DialogSodogu,
        }
      }]
      */
    },
    {
      path: "/d_start",
      name: "DiagnosisStart",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/DiagnosisStart.vue")
    },
    {
      path: "/d_start_text",
      name: "DiagnosisStart",
      beforeEnter: (to, from, next) => {
        if (router.app.$store.state.diagnosis_data_text == null) {
          next("/d_start");
        } else {
          next();
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "about" */ "../views/DiagnosisStartText.vue"
        )
    },
    {
      path: "/d_info",
      name: "DiagnosisInfo",
      beforeEnter: (to, from, next) => {
        if (router.app.$store.state.diagnosis_data == null) {
          next("/d_start");
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/DiagnosisInfo.vue")
    },
    {
      path: "/d_text",
      name: "DiagnosisText",
      beforeEnter: (to, from, next) => {
        if (router.app.$store.state.diagnosis_data == null) {
          next("/d_start");
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/DiagnosisText.vue")
    },
    {
      path: "/d_result",
      name: "DiagnosisResult",
      beforeEnter: (to, from, next) => {
        if (
          router.app.$store.state.diagnosis_data == null ||
          router.app.$store.state.diagnosis_data_result == null
        ) {
          next("/d_start");
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/DiagnosisResult.vue")
    },
    {
      path: "/counsel_intro",
      name: "CounselIntro",
      beforeEnter: (to, from, next) => {
        next();
      },
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/CounselIntro.vue")
    },
    {
      path: "/counsel_start",
      name: "CounselStart",
      beforeEnter: (to, from, next) => {
        if (router.app.$store.getters.getId != "") {
          next("/counsel_write");
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/CounselStart.vue")
    },
    {
      path: "/counsel_login",
      name: "CounselLogin",
      beforeEnter: (to, from, next) => {
        if (router.app.$store.getters.getId != "") {
          next("/counsel_write");
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/CounselLogin.vue")
    },
    {
      path: "/counsel_write",
      name: "CounselWrite",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/CounselWrite.vue")
    },
    {
      path: "/after_use_list",
      name: "AfterUseList",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/AfterUseList.vue")
    },
    {
      path: "/after_use_write",
      name: "AfterUseWrite",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/AfterUseWrite.vue")
    },
    {
      path: "/info",
      name: "Info",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/Info.vue")
    },
    {
      path: "/contents",
      name: "Contents",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/Contents.vue")
    },
    {
      path: "/find_pw",
      name: "FindPassword",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/FindPassword.vue")
    },
    {
      path: "/apply_gift",
      name: "ApplyGift",
      beforeEnter: (to, from, next) => {
        if (router.app.$store.getters.getId == "") {
          next("/counsel_login");
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/ApplyGift.vue")
    }
  ]
});

router.beforeEach(function(to, from, next) {
  //side hide
  router.app.$store.state.open_side_menu = false;

  //loading hide
  try {
    router.app.$store.state.loading.dismiss();
  } catch (e) {
    //empty
  }

  //팝업 띄움
  var target = to.fullPath.substring(to.fullPath.indexOf("#"));

  if (target == "#alert") {
    //내용이
    // 있으면 팝업 띄움
    if (router.app.$store.state.alert_title != "") {
      router.app.$store.state.alert = true;
    }
    // 없으면 안띄움
    else {
      router.app.$store.state.alert = false;
    }
  } else {
    router.app.$store.state.alert = false;
  }

  next();
});

export default router;
