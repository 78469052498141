import Vue from "vue";
import Vuex from "vuex";
import { HTTP } from "@ionic-native/http";
import { FileTransfer } from "@ionic-native/file-transfer";
import { vm } from "@/main";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    web_max_width: 900,
    platform: "web",
    url: "http://sum-sangjumind.com/api/",
    url_web: "/api/", //cors 때문에 web은 proxy 로 대체
    api_result: null,
    counseling_read: "N",
    p_cookie: "",
    push_token: "",
    hash_key: "",
    counsel_write_init: false,
    alert: false,
    alert_persistent: false,
    alert_title: "",
    alert_info: "",
    alert_function: null,
    alert_btn: "",
    alert_btn_text: "",
    loading: null,
    open_side_menu: false,
    open_toast: false,
    diagnosis_data: null,
    diagnosis_data_text: null,
    diagnosis_data_result: null,
    user_info: {
      kit_gubun: "Y", //Y : 신청가능, N : 이미 신청한 내역이 있어서, 신청 불가능
      tel: ""
    }
  },
  mutations: {
    api_result_update(state, data) {
      state.api_result = data;
    },
    id_update(state, id) {
      state.p_cookie = id;
      sessionStorage.p_cookie = id;
    },
    platform_update(state, type) {
      state.platform = type;
    },
    loading_update(state, id) {
      state.loading = id;
    },
    alert_show(
      state,
      { title, persistent = false, info, fn, btn_text = "예" }
    ) {
      state.alert_persistent = persistent;
      state.alert_title = title;
      state.alert_info = info;
      state.alert_btn_text = btn_text;

      //fn default는 뒤로가기
      if (fn == null) {
        state.alert_function = function() {
          vm.$router.go(-1);
        };
      } else {
        state.alert_function = fn;
      }

      var target = vm.$route.fullPath.substring(
        vm.$route.fullPath.indexOf("#")
      );
      //#alert가
      // 있는 상태이면 팝업만 띄움
      if (target == "#alert") {
        state.alert = true;
      }
      //아니면 push
      else {
        vm.$router.push({ hash: "#alert" });
      }
    },
    alert_hide(state) {
      state.alert_persistent = false;
      state.alert_title = "";
      state.alert_info = "";
      state.alert_function = null;
      vm.$router.push({ hash: "" });
    }
  },
  getters: {
    getId(state) {
      if (state.p_cookie == "") {
        // 로컬 스토리지에서 불러오기
        var { p_cookie } = sessionStorage;
        if (p_cookie == null || p_cookie == "null" || p_cookie == "undefined") {
          p_cookie = "";
        }
        state.p_cookie = p_cookie;
      }

      return state.p_cookie;
    }
  },
  actions: {
    async apiCall({ commit, state, getters }, { api, param }) {
      // web
      if (state.platform === "web") {
        var config = {
          headers: {
            //'p_cookie' : getters.getId
            Authorization:
              "Bearer " +
              getters.getId +
              "||" +
              state.hash_key +
              "||" +
              "web" +
              "||"
          }
        };

        var bodyFormData_web = new FormData();
        bodyFormData_web.append("data", btoa(JSON.stringify(param)));

        return await Vue.axios
          .post(state.url_web + api, bodyFormData_web, config)
          // response 값을 data에 선언한 contacts에 넣어줍니다.
          .then(function(response) {
            var data = response.data;

            commit("api_result_update", data);
          })
          .catch(function() {
            //error
            commit("api_result_update", "err");
          });
      } else {
        // HTTP.setDataSerializer('json');
        var header = {
          "Content-Type": "application/json"
        };
        if (state.platform == "android") {
          header = {
            // 'p_cookie' : getters.getId,
            Authorization:
              "Bearer " +
              getters.getId +
              "||" +
              state.hash_key +
              "||" +
              "app" +
              "||" +
              "and",
            enctype: "multipart/form-data; boundary=WebAppBoundary",
            "Content-Type": "multipart/form-data; boundary=WebAppBoundary"
          };
        } else if (state.platform == "ios") {
          header = {
            // 'p_cookie' : getters.getId,
            Authorization:
              "Bearer " +
              getters.getId +
              "||" +
              state.hash_key +
              "||" +
              "app" +
              "||" +
              "ios",
            enctype: "application/x-www-form-urlencodedy",
            "Content-Type": "application/x-www-form-urlencoded"
          };
        }

        var url = state.url + api;
        // var bodyFormData = new FormData();
        // bodyFormData.append("data", btoa(JSON.stringify(param)));
        var bodyFormData = { data: btoa(JSON.stringify(param)) };

        return await HTTP.post(url, bodyFormData, header)
          .then(result => {
            var data = result.data;

            if (data == "false") {
              data = false;
            }

            commit("api_result_update", JSON.parse(data));
          })
          .catch(() => {
            //500 에러 처리
            //error
            commit("api_result_update", "err");
          });
      }
    },
    async apiCallFile({ commit, state, getters }, { api, param, file }) {
      //web
      if (state.platform === "web") {
        var config = {
          headers: {
            // 'p_cookie' : getters.getId
            Authorization:
              "Bearer " +
              getters.getId +
              "||" +
              state.hash_key +
              "||" +
              "web" +
              "||"
          }
        };

        var bodyFormData_web = new FormData();
        bodyFormData_web.append("data", btoa(JSON.stringify(param)));
        bodyFormData_web.append("img", file);
        return await Vue.axios
          .post(state.url_web + api, bodyFormData_web, config)
          // response 값을 data에 선언한 contacts에 넣어줍니다.
          .then(function(response) {
            var data = response.data;
            commit("api_result_update", data);
          })
          .catch(function() {
            //error
            commit("api_result_update", "err");
          });
      }
      //mobile
      else {
        var url = state.url + api;

        if (file != null && file != "") {
          // img upload
          const fileTransfer = FileTransfer.create();

          let options = {
            fileKey: "img",
            fileName: "img.jpg",
            params: { data: btoa(JSON.stringify(param)) }
          };

          return await fileTransfer.upload(file, url, options).then(
            data => {
              // success
              commit("api_result_update", JSON.parse(data.response));
            },
            () => {
              //error
              commit("api_result_update", "err");
            }
          );
        } else {
          // text upload
          var header = {
            "Content-Type": "application/json"
          };
          if (state.platform == "android") {
            header = {
              // 'p_cookie' : getters.getId,
              Authorization:
                "Bearer " +
                getters.getId +
                "||" +
                state.hash_key +
                "||" +
                "app" +
                "||" +
                "and",
              enctype: "multipart/form-data; boundary=WebAppBoundary",
              "Content-Type": "multipart/form-data; boundary=WebAppBoundary"
            };
          } else if (state.platform == "ios") {
            header = {
              // 'p_cookie' : getters.getId,
              Authorization:
                "Bearer " +
                getters.getId +
                "||" +
                state.hash_key +
                "||" +
                "app" +
                "||" +
                "ios",
              enctype: "application/x-www-form-urlencodedy",
              "Content-Type": "application/x-www-form-urlencoded"
            };
          }

          var bodyFormData = { data: btoa(JSON.stringify(param)) };

          return await HTTP.post(url, bodyFormData, header)
            .then(result => {
              var data = result.data;

              if (data == "false") {
                data = false;
              }

              commit("api_result_update", JSON.parse(data));
            })
            .catch(() => {
              commit("api_result_update", "err");
            });
        }
      }
    },
    async loginGubun({ state, dispatch }) {
      var api = "api_login_gubun.php";
      var param = {};
      return await dispatch("apiCall", { api, param }).then(() => {
        var data = state.api_result;
        if (data == false) {
          //error
        } else {
          //success
          //로그인 정보 저장
          state.user_info.kit_gubun = data.kit_gubun;
          state.user_info.tel = data.tel;
        }
      });
    },
    async login({ commit, dispatch }, { p_cookie }) {
      commit("id_update", p_cookie);
      //사용자 정보 업데이트
      return await dispatch("loginGubun");
    },
    logout({ commit, state }) {
      commit("id_update", "");
      state.user_info.kit_gubun = "Y";
      state.user_info.tel = "";
    }
  },
  modules: {}
});
